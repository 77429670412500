<template>
    <u-inline-group>
        <li v-for="info in infos" :key="info.title" :class="info.clear == true ? 'clear' : ''">
            <u-txt-default class="txt--sm grey--text text--lighten-3">
                {{ info.title }} <span class="grey--text text--lighten-2">{{ info.text }}</span>
            </u-txt-default>
        </li>
    </u-inline-group>
</template>

<script>
import UInlineGroup from "@/components/publish/styles/groups/u-inline-group.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UInlineGroup,
        UTxtDefault,
    },
    data() {
        return {
            infos: [
                { title: "답십리점(패션봉제지원센터)", text: "02-2212-5586" },
                { title: "용신동점(패션봉제복합지원센터)", text: "02-6235-2201" },
                { title: "재단실", text: "02-2212-5581" },
                { title: "팩스", text: "02-3299-2622" },
                { title: "이메일", text: "dongori@ddm.go.kr", clear: true },
                { title: "주소", text: "주소 서울시 동대문구 답십리로 38길 19. 창업지원센터 A동 1층" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
